.check-order {
  height: calc(100% - 250px);
  margin: 150px 0 100px 0;
  align-items: center;
}

.check-order .check-order-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.check-order .check-order-wrapper .main-content {
  width: 40%;
  height: max-content;
}

.check-order .check-order-wrapper .main-content .order-info {
  border: 1px solid black;
  width: 100%;
  border-radius: 10px;
  color: black;
  background-color: white;
}

.check-order .check-order-wrapper .main-content .order-info h1 {
  text-transform: uppercase;
  text-align: center;
  margin: 30px 0 10px 0;
  font-size: 36px;
  font-weight: 700;
  line-height: normal;
}

.check-order .check-order-wrapper .main-content .order-info p {
  text-align: center;
  margin: 0;
  line-height: normal;
  font-size: 16px;
}

.check-order .check-order-wrapper .main-content .order-info .order-info-form {
  margin-top: 40px;
}

.main-content .order-info h4 {
  margin-left: 10%;
  margin-top: 30px;
  font-size: 20px;
  line-height: normal;
  font-weight: 700;
}

.main-content .order-info .order-code-input {
  width: 80%;
  height: 50px;
  margin-left: 10%;
  border-color: black;
  line-height: normal;
  font-size: 18px;
}

.main-content .order-info .order-code-input::-webkit-outer-spin-button,
.main-content .order-info .order-code-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.main-content .order-info .order-info-form .ant-form-item-explain {
  width: 100%;
}

.main-content .order-info .order-info-form .ant-form-item-explain-error {
  width: 80% !important;
  margin-left: 10%;
  font-size: 18px;
  color: red;
}

.main-content .order-info .order-info-form .form-submit-button {
  width: 80%;
  margin: 30px 10%;
  height: 60px;
  background-color: #FAE12C;
  color: black;
  border-radius: 8px;
}

.check-order .check-order-wrapper .main-content .mobile-app-link {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}

.check-order .check-order-wrapper .main-content .mobile-app-link a {
  width: 45%;
}

.check-order .check-order-wrapper .main-content .mobile-app-link a img {
  height: auto;
  width: 100% !important;
}

.check-order .check-order-wrapper img {
  width: 55%;
}

.check-order .error-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  display: flex;
}

.check-order .error-layer.hide {
  display: none;
}

.check-order .error-layer .error-box {
  position: relative;
  margin: auto;
  width: 500px;
  height: 400px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.check-order .error-layer .error-box .error-icon {
  height: 100px;
  width: 100px;
  background-color: #EF513A;
  border-radius: 50%;
  margin-top: -50px;
}

.check-order .error-layer .error-box .error-icon::before {
  content: '';
  position: absolute;
  width: 60px;
  height: 10px;
  background: #fff;
  border-radius: 4px;
  transform: translateY(45px) translateX(20px) rotate(45deg);
}

.check-order .error-layer .error-box .error-icon::after {
  content: '';
  position: absolute;
  width: 60px;
  height: 10px;
  background: #fff;
  border-radius: 4px;
  transform: translateY(45px) translateX(20px) rotate(-45deg);
}

.check-order .error-layer .error-box .message-title {
  color: black;
  margin-top: 30px;
  line-height: normal;
  font-weight: 700;
  font-size: 45px;
}

.check-order .error-layer .error-box .message-content {
  color: black;
  margin-top: 15px;
  text-align: center;
  line-height: normal;
  font-weight: 600;
  font-size: 30px;
}

.check-order .error-layer .error-box button {
  position: absolute;
  bottom: 30px;
  background-color: #EF513A;
  color: white;
  font-weight: 700;
  padding: 12px 60px;
  border-width: 0;
  border-radius: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 1300px) {
  .check-order .check-order-wrapper .main-content {
    width: 50%;
  }

  .check-order .check-order-wrapper img {
    width: 47%;
  }
}

@media only screen and (max-width: 1024px) {
  .check-order .check-order-wrapper .main-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .check-order .check-order-wrapper #background-image {
    display: none;
  }

  .check-order .check-order-wrapper .main-content .mobile-app-link {
    max-width: 600px;
  }
}

@media only screen and (max-width: 768px) {
  .check-order {
    height: calc(100% - 170px);
    margin: 85px 0 85px 0;
    align-items: center;
  }

  .check-order .check-order-wrapper .main-content .order-info h1 {
    font-size: 32px;
  }

  .check-order .check-order-wrapper .main-content .order-info p {
    font-size: 14px;
  }

  .main-content .order-info .order-code-input {
    font-size: 16px;
  }

  .check-order .error-layer .error-box {
    width: 420px;
    height: 350px;
  }

  .check-order .error-layer .error-box .message-title {
    font-size: 40px;
    margin-top: 20px;
  }

  .check-order .error-layer .error-box .message-content {
    font-size: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .check-order .check-order-wrapper .main-content .order-info .order-info-form {
    margin-top: 25px;
  }

  .check-order .check-order-wrapper .main-content .order-info h1 {
    font-size: 28px;
    margin-top: 20px;
  }

  .check-order .check-order-wrapper .main-content .order-info p {
    font-size: 12px;
  }

  .check-order .check-order-wrapper .main-content .order-info .order-info-form {
    margin-top: 20px;
  }
  
  .main-content .order-info h4 {
    margin-top: 10px;
    margin-left: 5%;
  }

  .main-content .order-info .form-input {
    margin-bottom: 0px;
  }
  
  .main-content .order-info .order-code-input {
    width: 90%;
    margin-left: 5%;
    height: 45px;
    font-size: 14px;
  }

  .main-content .order-info .order-info-form .form-submit-button {
    width: 90%;
    margin: 10px 5% 20px 5%;
    height: 50px;
  }

  .main-content .order-info .order-info-form .ant-form-item-explain-error {
    margin-left: 5%;
    font-size: 14px;
    width: 90% !important;
  }

  .check-order .error-layer .error-box {
    width: calc(100% - 60px);
  }
}
