.btn-change-status{
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 700;
    font-family: 'QUicksand', sans-serif;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.left-text{
    padding: 1px;
}
@media screen and (max-width: 480px) {
    .note-box{
        height: auto;
        margin-bottom: 20px;
    }
    
}
