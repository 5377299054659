/* Giữ nguyên CSS hiện tại */
.service-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  padding: 12px;
  background-color: white;
  border-radius: 8px;
  max-width: 500px;
  /* margin: 0 auto; */
}

/* Thêm media queries cho mobile */
@media screen and (max-width: 480px) {
  .service-list {
      justify-content: flex-start; /* Căn trái để scroll */
      overflow-x: auto; /* Cho phép scroll ngang */
      overflow-y: hidden; /* Ẩn scroll dọc */
      -webkit-overflow-scrolling: touch; /* Smooth scroll cho iOS */
      scroll-snap-type: x mandatory; /* Snap khi scroll */
      gap: 8px; /* Giảm khoảng cách giữa các items trên mobile */
      padding: 12px 16px; /* Thêm padding ngang */
      margin: 0 -16px; /* Mở rộng container ra ngoài padding của parent */
      width: calc(100% + 32px); /* Bù trừ cho margin âm */
      border-radius: 0; /* Bỏ border radius khi full width */
      
      /* Ẩn scrollbar nhưng vẫn cho phép scroll */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
  }

  /* Ẩn scrollbar cho Chrome, Safari và Opera */
  .service-list::-webkit-scrollbar {
      display: none;
  }

  /* Thêm padding giả để item cuối không bị cắt */
  .service-list::after {
      content: '';
      padding-right: 16px;
  }
}

/* Thêm smooth scroll cho các trình duyệt hỗ trợ */
@supports (scroll-behavior: smooth) {
  .service-list {
      scroll-behavior: smooth;
  }
}