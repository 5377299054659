.detail-container {
    display: flex;
    width: 770px; 
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px; 
    margin-bottom: 30px;
}

.payment-info, 
.travel-info {
    flex: 1; 
    display: flex;
    flex-direction: column;
}

/*Payment Info*/
.label-payment {
    font-size: 14px;
    font-weight: 700;
    font-family: 'Quicksand';
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-label {
    font-size: 14px;
    font-weight: 700;
    font-family: 'Quicksand';
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 8px;
}

.left-text{
    font-size: 12px;
    font-weight: 700;
    font-family: 'Quicksand';
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.text-box-pay {
    width: 100%;
    height: auto;
    border: 1px solid #ccc;  
    border-radius: 8px; 
    background-color: white; 
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    padding-top: 10px;
    padding-left: 10px;
    font-size: 12px;
    font-weight: 700;
    font-family: 'Quicksand';
    margin-bottom: 6px;
}

.text-box-pay2 {
    width: 100%;
    height: auto;
    border: 1px solid #ccc; 
    border-radius: 8px; 
    background-color: white; 
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    justify-content: space-between; 
    padding: 10px;
    padding-bottom: 0px !important;
    font-size: 12px;
    font-weight: 700;
    font-family: 'Quicksand';
    line-height: 1.0;
    margin-bottom: 6px;
}

.text-content-pay {
    flex: 1; 
    display: flex;
    flex-direction: column;
    margin-right: 10px; 
}

/* .text-box img {
    width: 107px ;
    height: 107px ;
    object-fit: contain;
    flex-shrink: 0; 
    display: block;
    max-width: 107px; 
    max-height: 107px;
} */

/*travel detail*/
.button-expand-travel-info {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 8px;
    cursor: pointer;
    width: 375px;
    background-color: #2AB93F;
    color: white;
    border-radius: 8px;
    height: auto;
    border: 2px solid rgba(42, 185, 63, 0.5); 
    box-shadow: 0 0 10px rgba(42, 185, 63, 0.5); 
    transition: all 0.3s ease-in-out;
}
.icon-detail-special{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right:10px;
}
.icon-detail-special img{
    width: 24px;
    height: 24px;
}

@media screen and (max-width: 768px) {
    .detail-container {
        width: 100%;
        flex-direction: column;
        gap: 10px;
    }

    .payment-info,
    .travel-info {
        width: 100%;
    }

    .button-expand-travel-info {
        width: 100%;
        padding: 12px 10px;  
    }

    .text-box-pay2 {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .text-content-pay {
        width: 80%; 
        margin-right: 5px;
    }

    .text-box-pay2 img [data-type="qr"] {
        width: 80px !important ;
        height: 80px !important;
        max-width: 20%; 
        object-fit: contain; 
        display: block;

    }
    .text-box-pay2 img [data-type="tel"] {
        width: 44px !important ;
        height: 44px !important;
        max-width: 20%; 
        object-fit: contain; 
        display: block;

    }
    

    .label-payment {
        text-align: center;
        padding: 0 10px;
    }

    .left-label {
        font-size: 13px;
    }

    .left-text {
        font-size: 11px;
    }
}

@media screen and (max-width: 480px) {
    .text-box-pay, 
    .text-box-pay2 {
        padding: 8px;
        padding-bottom: 3px;
    }

    .text-box-pay2 {
        align-items: center;
    }

    .text-content-pay {
        width: 65%;
        padding-bottom: 0px;
    }

    /* .text-box-pay2 img[data-type="tel"]{
        width: 40px !important ;
        height: 40px !important ;
        margin-top: 0;
    } */
    .icon-detail-special img{
        width: 24px !important;
        height: 24px !important;
        object-fit: contain;
        align-self:center;
    }
    .text-box-pay2 img[data-type="qr"]{
        width: 80px !important;
        height: 80px !important;
        max-width: 35%;
        align-self: center;
        margin-top: 0; /* Giữ ảnh cân đối với text */
        margin-bottom: 10px;
    }

    .left-label {
        font-size: 14px;
    }

    .left-text {
        font-size: 12px;
        margin-bottom: 5px; /* Thêm khoảng cách giữa các dòng text */
    }
}