/* ServiceItem.css */
.service-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    color: #9ca3af;
  }
  
  .service-icon {
    width: 64px;
    height: 64px;
    background-color: #f3f4f6;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    transition: background-color 0.3s ease;
    border-radius: 15%;
  }
  
  .service-icon img {
    width: 32px;
    height: 32px;
    filter: grayscale(100%);
    opacity: 0.7;
    transition: filter 0.3s ease, opacity 0.3s ease;
  }
  
  .service-label {
    font-size: 12px;
    text-align: center;
    color: #9ca3af;
    transition: color 0.3s ease;
    font-family: 'Quicksand';
    font-weight: 700;
    line-height: 18px;
  }
  
  .service-icon.active {
    background-color: #000;
  }
  
  .service-icon.active img {
    filter: brightness(0) invert(1);
    opacity: 1;
  }
  
  .service-label.active {
    color: #000;
    font-weight: 700;
  }