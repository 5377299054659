/* Container chính */
.checkdon {
  width: 100%;
  height: calc(100vh - var(--header-height));
  margin-top: var(--header-height);
  overflow-y: auto;
  position: relative;
}

.check-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 20px;
  min-height: 100%;
}

/* Form Styles */
.head-label {
  font-family: 'Quicksand';
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: black;
  justify-content: center;
  align-items: center;
  display: flex;
}

.label {
  font-family: 'Quicksand';
  font-weight: 700;
  font-size: 14px;
  line-height: 17.5px;
  color: black;
  justify-content: left;
  display: flex;
}

.check-form {
  display: flex;
  flex-direction: column;
  /* margin-top: 20px; */
  width: 375px;
}

.check-input {
  font-family: 'Quicksand';
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  border-radius: 8px;
  padding: 10px;
  align-items: center;
  width: 100% !important;
  height: 40px !important;
}

.check-input:focus {
  border: 2px solid #FFD700;
}

.check-button {
  font-family: 'Quicksand';
  font-weight: 700;
  font-size: 14px;
  line-height: 17.5px;
  color: white;
  background-color: black;
  border-radius: 8px;
  padding: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 100px;
  width: 375px;
}

/* Ant Design Overrides */
.ant-form-item {
  margin-bottom: 10px !important;
}
.ant-picker {
  font-family: 'Quicksand';
  font-weight: 700;
  font-size: 12px;
}
.ant-picker-input input{
  font-family: 'Quicksand';
  font-weight: 700;
  font-size: 12px;
}
.full-width-datepicker {
  width: 100% !important;
}

/* Modal Styles */
.contact-modal .ant-modal-content {
  border: none;
  box-shadow: none;
  border-radius: 8px;
  padding: 24px;
  background-color: transparent;
}

.contact-modal .ant-modal-body {
  background-color: white;
  border-radius: 8px;
}

.contact-modal .ant-modal-mask {
  background-color: rgba(255, 255, 255, 0.95);
  position: fixed !important;
  top: var(--header-height) !important;
  height: calc(100vh - var(--header-height)) !important;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.modal-title {
  font-size: 20px;
  font-weight: 700;
  font-family: 'Quicksand';
  line-height: 16px;
  margin: 0;
}

.form-input-modal {
  font-size: 14px;
  font-weight: 700;
  font-family: 'Quicksand';
  line-height: 20.51px;
}

.contact-input {
  height: 40px;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Quicksand';
  line-height: 15px;
}

.confirm-button {
  width: 100%;
  height: 44px;
  border-radius: 4px;
  font-weight: 500;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  margin-top: 50px;
}



/* Modal wrapper styles */
.transparent-modal-wrapper {
  pointer-events: auto;
}

.transparent-modal-wrapper .ant-modal {
  padding-bottom: 0;
}

.transparent-modal-wrapper .ant-modal-content {
  background-color: transparent;
}

.contact-modal::before,
.contact-modal::after {
  display: none !important;
}

.finish-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
  color: #2AB93F;
}
.text-finish{
  display: flex;
  font-family: 'Quicksand';
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  color: black;
  justify-content: center;
  align-items: center;
 
}
.success{
  margin-top: 8px;
  font-family: 'Quicksand';
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.confirm-button-error {
 
  font-family: 'Quicksand';
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  color: white;
  background-color: #EF513A;
  border-radius: 8px; 
  width: 188px !important;
  margin: 50px auto 24px !important;
  display: block !important;
  height: 40px !important;
}
/* Giữ nguyên các CSS cũ và thêm media queries cho mobile */

/* Các styles hiện tại giữ nguyên không đổi */

/* Thêm media queries cho mobile */
@media screen and (max-width: 480px) {
  .check-container {
      height: calc(100vh - var(--header-height));
      padding-bottom: env(safe-area-inset-bottom);
  }

  .check-content {
      padding: 16px;
  }

  .head-label {
      font-size: 24px;
      line-height: 30px;
      padding: 0 10px;
      text-align: center;
      margin-bottom: 16px;
  }

  .check-form {
      width: 100%;
      padding: 0;
  }

  .check-input {
      height: 44px !important; /* Tăng kích thước cho dễ chạm */
  }

  .check-button {
      width: 100%;
      margin-top: 20px;
  }

  /* Modal adjustments for mobile */
  .contact-modal {
      width: 90% !important;
      margin: 0 auto !important;
  }

  .contact-modal .ant-modal-content {
      width: 100%;
      margin: 0;
      border-radius: 8px;
  }

  .modal-header {
      padding: 12px;
  }

  .modal-title {
      font-size: 18px;
  }

  .form-input-modal {
      margin-bottom: 12px;
  }

  .contact-input {
      height: 44px;
  }

  /* Success modal adjustments */
  .finish-icon {
      margin: 16px 0;
  }

  .finish-icon img {
      width: 80px;
      height: auto;
  }

  .success {
      font-size: 20px;
      margin: 12px 0;
  }

  .text-finish {
      font-size: 14px;
      line-height: 1.5;
      padding: 0 12px;
  }

  .confirm-button-finish {
      font-size: 20px;
      height: 44px;
      margin-top: 16px;
  }

  /* Form element improvements */
  .ant-picker {
      height: 44px !important;
  }

  .ant-picker-input input {
      font-size: 14px !important;
  }

  /* Touch target improvements */
  .modal-close-icon,
  .ant-picker-suffix {
      padding: 8px;
      min-height: 32px;
      min-width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
  }
}

/* iOS specific adjustments */
@supports (-webkit-touch-callout: none) {
  @media screen and (max-width: 480px) {
      .check-input,
      .contact-input,
      .ant-picker-input input {
          font-size: 16px !important; /* Prevent zoom on focus */
      }
  }
}