.check-layout {
  display: flex;
  align-items: center;
  position: relative;
  height: max-content;
  min-height: 100vh;
  width: 100%;
  background: url(../../public/Image/maps88.png) no-repeat center;
  background-size: cover;
}
