.home-page {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}

.home-page .first-component {
  width: 100%;
  height: max-content;
  margin-top: 150px;
}

.home-page .first-component .flex-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5%;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .home-page .first-component {
    margin-top: 100px;
  }

  .home-page .first-component .flex-box {
    flex-direction: column;
  }

  .home-page .first-component .flex-box-reverse {
    flex-direction: column-reverse;
  }
}

.first-component .flex-box img {
  height: 100%;
  width: 100%;
  max-width: none;
  min-height: inherit;
  object-fit: cover;
  object-position: center;
}

.home-page .second-component-pc {
  height: max-content;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .home-page .second-component-pc {
    display: none;
  }
}

.home-page .second-component-pc .left-side-content {
  width: 55%;
}

.home-page .second-component-pc .slide-order {
  position: sticky;
  position: -webkit-sticky;
  font-size: 30px;
  display: flex;
  top: 90vh;
  height: 0;
  z-index: 900;
}

.second-component-pc .slide-order .dot-position {
  background-color: #cccccc;
  height: 2vh;
  width: 2vh;
  cursor: pointer;
  border-radius: 50%;
  margin-right: 2.7vh;
}

.second-component-pc .slide-order .dot-position.selected {
  background-color: black;
}

.home-page .second-component-pc .text-content {
  width: 100%;
  height: max-content;
  position: relative;
  z-index: 100;
}

.second-component-pc .text-content .text-box {
  height: 100vh;
  width: 60%;
  font-size: 75px;
  display: flex;
  flex-direction: column;
  
  
}

.second-component-pc .text-content .text-box .text {
  margin: auto 0;
  /* font-family: Instagram Sans, Helvetica Neue, Helvetica, Arial, sans-serif; */
}

.second-component-pc .text-content .text-box .text p {
  font-size: 35px;
}

.home-page .second-component-pc .img-content {
  height: 100vh;
  /* padding-top: 10vh; */
  width: max-content;
  width: 45%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
}

.home-page .second-component-pc .img-content .image-box {
  position: relative;
  width: max-content;
  width: 100%;
  height: 75%;
  margin: auto 0;
}

@media only screen and (max-width: 1024px) {
  .home-page .second-component-pc .img-content .image-box {
    height: 50%;
  }
}

.home-page .second-component-pc .img-content .media {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: auto;
  max-width: 100%;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
  border: 3px solid black;
  border-radius: 10% / 5%;
}

.home-page .second-component-pc .img-content .media.media-selected {
  opacity: 1;
}

.home-page .second-component-mobile {
  display: none;
  margin-top: 50px;
}

@media only screen and (max-width: 768px) {
  .home-page .second-component-mobile {
    display: block;
  }
}

.second-component-carousel .slick-dots li {
  height: 5vw;
  width: 5vw !important;
  margin: 0 3vw !important;
}

.second-component-mobile .second-component-carousel .slick-dots button {
  height: 5vw;
  width: 5vw;
  background-color: #cccccc;
  border-radius: 50%;
}

.second-component-carousel .slick-dots .slick-active button {
  background-color: black !important;
}

.second-component-mobile .second-component-item {
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  width: 100%;
  padding-bottom: 15vw;
}

.second-component-mobile .second-component-item .media-mobile {
  height: auto;
  width: 60%;
  border: 3px solid black;
  border-radius: 10% / 5%;
  min-height: 300px;
  margin-top: 20px;
}