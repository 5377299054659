/* Styles cho Loading Modal */
.loading-modal .ant-modal-content {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border: none; /* Loại bỏ border */
  background-color: white;
}

/* Loại bỏ border và shadow của modal */
.loading-modal.ant-modal {
  border: none;
}

/* Loại bỏ border của modal body */
.loading-modal .ant-modal-body {
  border: none;
  padding: 20px;
}

  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;    
    justify-content: center;
    padding: 20px 0;
    border: none;
  }
  
  .loading-container h3 {
    font-weight: 500;
    font-family: 'Quicksand';
    font-weight: 700;
    font-size: 24px;
    color: #333;
    margin-bottom: 0;
  }
  
  .loading-container p {
    color: #666;
    font-family: 'Quicksand';
    margin-top: 8px;
  }
  
  /* Tuỳ chỉnh spinner */
  .loading-container .ant-spin {
    margin-bottom: 10px;
  }
  
  .loading-container .anticon-loading {
    color: #1890ff; /* Hoặc màu chính của ứng dụng của bạn */
    
  }