.delete-account {
    height: calc(100% - 250px);
    margin: 150px 0 100px 0;
    align-items: center;
  }
  
  .delete-account .delete-account-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .delete-account .delete-account-wrapper .main-content {
    width: 40%;
    height: max-content;
  }
  
  .delete-account .delete-account-wrapper .main-content .account-info {
    border: 1px solid #011A4B;
    width: 100%;
    border-radius: 10px;
    color: #011A4B;
    background-color: white;
  }
  
  .delete-account .delete-account-wrapper .main-content .account-info h1 {
    text-transform: uppercase;
    text-align: center;
    margin: 30px 0 10px 0;
    font-size: 36px;
    font-weight: 700;
    line-height: normal;
  }
  
  .delete-account .delete-account-wrapper .main-content .account-info p {
    text-align: center;
    margin: 0;
    line-height: normal;
    font-size: 16px;
  }
  
  .delete-account .delete-account-wrapper .main-content .account-info .account-info-form {
    margin-top: 40px;
  }
  
  .main-content .account-info h4 {
    margin-left: 10%;
    color: #011A4B;
    margin-top: 30px;
    font-size: 20px;
    line-height: normal;
    font-weight: 600;
  }
  
  .main-content .account-info .account-code-input {
    width: 80%;
    height: 50px;
    margin-left: 10%;
    border-color: #011A4B;
    line-height: normal;
    font-size: 18px;
  }
  
  .main-content .account-info .account-code-input::-webkit-outer-spin-button,
  .main-content .account-info .account-code-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .main-content .account-info .account-info-form .ant-form-item-explain {
    width: 100%;
  }
  
  .main-content .account-info .account-info-form .ant-form-item-explain-error {
    width: 80% !important;
    margin-left: 10%;
    font-size: 18px;
    color: red;
  }
  
  .main-content .account-info .account-info-form .form-submit-button {
    width: 80%;
    margin: 30px 10%;
    height: 60px;
    background-color: #FAE12C;
    color: #011A4B;
    border-radius: 8px;
  }
  
  .delete-account .delete-account-wrapper .main-content .mobile-app-link {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
  }
  
  .delete-account .delete-account-wrapper .main-content .mobile-app-link a {
    width: 45%;
  }
  
  .delete-account .delete-account-wrapper .main-content .mobile-app-link a img {
    height: auto;
    width: 100% !important;
  }
  
  .delete-account .delete-account-wrapper img {
    width: 55%;
  }
  
  .delete-account .success-layer,
  .delete-account .error-layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
    display: flex;
  }
  
  .delete-account .success-layer.hide,
  .delete-account .error-layer.hide {
    display: none;
  }

  .delete-account .success-layer .success-box,
  .delete-account .error-layer .error-box {
    position: relative;
    margin: auto;
    width: 500px;
    height: 400px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .delete-account .error-layer .error-box .error-icon {
    height: 100px;
    width: 100px;
    background-color: #EF513A;
    border-radius: 50%;
    margin-top: -50px;
  }

    
  .delete-account .success-layer .success-box .success-icon{
      height: 100px;
      width: 100px;
      background-color: #05a212;
      border-radius: 50%;
      margin-top: -50px;
    }
  

  .delete-account .error-layer .error-box .error-icon::before {
    content: '';
    position: absolute;
    width: 60px;
    height: 10px;
    background: #fff;
    border-radius: 4px;
    transform: translateY(45px) translateX(20px) rotate(45deg);
  }
  
  .delete-account .error-layer .error-box .error-icon::after {
    content: '';
    position: absolute;
    width: 60px;
    height: 10px;
    background: #fff;
    border-radius: 4px;
    transform: translateY(45px) translateX(20px) rotate(-45deg);
  }

  .delete-account .success-layer .success-box .success-icon::before {
    content: '';
    position: absolute;
    width: 40px;
    height: 10px;
    background: #fff;
    border-radius: 4px;
    transform: translateY(52px) translateX(12px) rotate(45deg);
  }
  
  .delete-account .success-layer .success-box .success-icon::after {
    content: '';
    position: absolute;
    width: 60px;
    height: 10px;
    background: #fff;
    border-radius: 4px;
    transform: translateY(45px) translateX(30px) rotate(-45deg);
  }
  
  .delete-account .success-layer .success-box .message-title,
  .delete-account .error-layer .error-box .message-title {
    color: #011A4B;
    margin-top: 30px;
    line-height: normal;
    font-weight: 700;
    font-size: 45px;
  }
  
  .delete-account .success-layer .success-box .message-content,
  .delete-account .error-layer .error-box .message-content {
    color: #011A4B;
    margin-top: 15px;
    text-align: center;
    line-height: normal;
    font-weight: 600;
    font-size: 30px;
  }
  
  .delete-account .error-layer .error-box button {
    position: absolute;
    bottom: 30px;
    background-color: #EF513A;
    color: white;
    font-weight: 700;
    padding: 12px 60px;
    border-width: 0;
    border-radius: 10px;
    cursor: pointer;
  }

    .delete-account .success-layer .success-box button{
      position: absolute;
      bottom: 30px;
      background-color: #05a212;
      color: white;
      font-weight: 700;
      padding: 12px 60px;
      border-width: 0;
      border-radius: 10px;
      cursor: pointer;
    }
  
  @media only screen and (max-width: 1300px) {
    .delete-account .delete-account-wrapper .main-content {
      width: 50%;
    }
  
    .delete-account .delete-account-wrapper img {
      width: 47%;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .delete-account .delete-account-wrapper .main-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .delete-account .delete-account-wrapper #background-image {
      display: none;
    }
  
    .delete-account .delete-account-wrapper .main-content .mobile-app-link {
      max-width: 600px;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .delete-account {
      height: calc(100% - 170px);
      margin: 85px 0 85px 0;
      align-items: center;
    }
  
    .delete-account .delete-account-wrapper .main-content .account-info h1 {
      font-size: 32px;
    }
  
    .delete-account .delete-account-wrapper .main-content .account-info p {
      font-size: 14px;
    }
  
    .main-content .account-info .account-code-input {
      font-size: 16px;
    }
  
    .delete-account .success-layer .success-box,
    .delete-account .error-layer .error-box {
      width: 420px;
      height: 350px;
    }
  
    .delete-account .success-layer .success-box .message-title,
    .delete-account .error-layer .error-box .message-title {
      font-size: 40px;
      margin-top: 20px;
    }
  
    .delete-account .success-layer .success-box .message-content,
    .delete-account .error-layer .error-box .message-content {
      font-size: 20px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .delete-account .delete-account-wrapper .main-content .account-info .account-info-form {
      margin-top: 25px;
    }
  
    .delete-account .delete-account-wrapper .main-content .account-info h1 {
      font-size: 28px;
      margin-top: 20px;
    }
  
    .delete-account .delete-account-wrapper .main-content .account-info p {
      font-size: 12px;
    }
  
    .delete-account .delete-account-wrapper .main-content .account-info .account-info-form {
      margin-top: 20px;
    }
    
    .main-content .account-info h4 {
      margin-top: 10px;
      margin-left: 5%;
    }
  
    .main-content .account-info .form-input {
      margin-bottom: 0px;
    }
    
    .main-content .account-info .account-code-input {
      width: 90%;
      margin-left: 5%;
      height: 45px;
      font-size: 14px;
    }
  
    .main-content .account-info .account-info-form .form-submit-button {
      width: 90%;
      margin: 10px 5% 20px 5%;
      height: 50px;
    }
  
    .main-content .account-info .account-info-form .ant-form-item-explain-error {
      margin-left: 5%;
      font-size: 14px;
      width: 90% !important;
    }
  
    .delete-account .success-layer .success-box,
    .delete-account .error-layer .error-box {
      width: calc(100% - 60px);
    }
  }
  