/* Base styles for mobile */
.detail-container-customer {
    display: flex;
    width: 100%;
    max-width: 375px;
    margin:0 auto;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    padding: 0 10px;
    box-sizing: border-box;
    margin-bottom: 50px;
}

.travel-info-customer {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;

}

.button-expand-travel-info-customer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 10px 10px 8px;
    cursor: pointer;
    width: 100%;
    background-color: #2AB93F;
    color: white;
    border-radius: 8px;
    height: auto;
    border: 2px solid rgba(42, 185, 63, 0.5);
    box-shadow: 0 0 10px rgba(42, 185, 63, 0.5);
    transition: all 0.3s ease-in-out;
}

/* Small mobile devices (320px and up) */
@media screen and (min-width: 320px) {
    .detail-container-customer {
        max-width: 320px;
    }
}

/* Standard mobile devices (375px and up) */
@media screen and (min-width: 375px) {
    .detail-container-customer {
        max-width: 375px;
    }
}

/* Large mobile devices (425px and up) */
@media screen and (min-width: 425px) {
    .detail-container-customer {
        max-width: 375px; /* Keep width at 375px */
    }
}

/* Tablets (768px and up) */
@media screen and (min-width: 768px) {
    .detail-container-customer {
        max-width: 375px; /* Keep width at 375px */
    }
    
    .button-expand-travel-info-customer {
        padding: 12px 15px;
    }
}

/* Laptops/Desktops (1024px and up) */
@media screen and (min-width: 1024px) {
    .detail-container-customer {
        max-width: 375px; /* Fixed width at 375px for laptops as specified */
    }
}

/* Large screens (1440px and up) */
@media screen and (min-width: 1440px) {
    .detail-container-customer {
        max-width: 375px; /* Keep width at 375px */
    }
}

/* Support for dark mode */
@media (prefers-color-scheme: dark) {
    .button-expand-travel-info-customer {
        background-color: #1e9a30;
        border: 2px solid rgba(30, 154, 48, 0.7);
    }
}