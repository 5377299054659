.App {
  width: 100%;
  height: max-content;
  box-sizing: border-box;
  padding: 0;
  position: relative;
}

.component-layout {
  width: 100%;
  display: flex;
  justify-content: center;
}

.component-wrapper {
  width: 100%;
  max-width: 1600px;
  padding: 0 7.142vw;
}

@media only screen and (min-width: 1920px) {
  .component-wrapper {
    padding: 0;
  }
}

@media only screen and (min-width: 1800px) and (max-width: 1919px) {
  .component-wrapper {
    padding: 0 3.571vw;
  }
}

.heading-4 {
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
}

@media only screen and (max-width: 768px) {
  .heading-4 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .heading-4 {
    font-size: 16px;
  }
}
