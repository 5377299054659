:root {
    --header-height: 64px;
    --primary-color: #000000;
    --secondary-color: #FFFFFF;
  }
  
  /* Container chính cho toàn bộ layout */
  .layout-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: var(--secondary-color);
  }
  
  /* Container cho header */
  .header-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--header-height);
    background-color: var(--secondary-color);
    z-index: 1000;
  }
  
  /* Container cho nội dung chính */
  .content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    min-height: calc(100vh - var(--header-height));
    position: relative;
    
  }
  
  /* Media queries cho responsive */
  @media screen and (max-width: 768px) {
    :root {
      --header-height: 56px; /* Giảm chiều cao header trên mobile */
    }
    
    .content-container {
      padding: 0 16px;
      overflow: auto;
    }
  }
  
  /* Utility classes */
  .flex {
    display: flex;
  }
  
  .flex-col {
    flex-direction: column;
  }
  
  .items-center {
    align-items: center;
  }
  
  .justify-center {
    justify-content: center;
  }
  
  .w-full {
    width: 100%;
  }
  
  .h-full {
    height: 100%;
  }
  
  /* Z-index management */
  .z-header {
    z-index: 1000;
  }
  
  .z-content {
    z-index: 1;
  }
  
  .z-modal {
    z-index: 1100;
  }