.avatar{
    width: 57px;
    height: 57px;
    border-radius: 50%;
    margin: 0 auto;
    display: block;
    margin-bottom: 10px;
    margin-right: 10px;
}
.btn-response{
    background-color: black;
    color: white;
    font-family: 'Quicksand';
    font-weight: 700;
    font-size: 14px;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 30px;
}
.rating-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;
  }
  .rating-button-custom {
    height: 44px;
    width: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: 'Quicksand';
    font-weight: 700;
    border: 1px solid #d9d9d9;
    
  }
  
  .ant-radio-button-wrapper::before {
    display: none !important;
  }
  .ant-radio-button-wrapper::after {
    display: none !important;
  }

  
  .ant-radio-button-wrapper:first-child,
  .ant-radio-button-wrapper:last-child {
    border-radius: 8px;
  }
  
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)[value="CÓ"] {
    background-color: black !important;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }
  
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)[value="KHÔNG"] {
    background-color: black !important;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }
 
  .rating-button:not(.selected) {
    background-color: white!important;
  }
.responsive-avatar-text{
    display: flex ;
    flex-direction: row;
    justify-content: center ;
    align-items: center ;
    text-align: center ;
}
@media screen and (max-width: 480px) {
    .left-text {
        font-size: 14px;
    }
    .text-box-pay2.box-response {
      display: flex !important;
      flex-direction: column !important;  
      justify-content: center !important;
      align-items: center !important;
      text-align: center !important;
    }
  .responsive-avatar-text{
    display: flex ;
    flex-direction: row;
    justify-content: center ;
    align-items: center ;
    text-align: center ;
  }
  .responsive-icon{
    display: flex ;
    flex-direction: row ;
    justify-content: center ;
    align-items: center ;
    text-align: center ;
  }
}